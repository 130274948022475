import React from "react";
import ScrollingEffect from "../components/ScrollingEffect/ScrollingEffect";
import { Link } from "react-router-dom";
import {
  Home,
  Shield,
  Zap,
  Wifi,
  SmartphoneCharging,
  Lock,
  Thermometer,
  ChevronRight,
} from "lucide-react";
import { ServiceCard } from "../components/ServiceCard";
import Footer from "../components/Footer";

const services = [
  {
    icon: Home,
    title: "Pack Essentiel",
    description: "Solution de base pour commencer votre maison connectée",
    features: [
      "Contrôle de l'éclairage",
      "Thermostat intelligent",
      "Détecteur de mouvement",
      "Contrôle via smartphone",
      "Installation et configuration",
    ],
    price: "À partir de 690€",
  },
  {
    icon: Shield,
    title: "Pack Sécurité",
    description: "Protection complète pour votre domicile",
    features: [
      "Caméras de surveillance HD",
      "Serrures connectées",
      "Détecteurs d'intrusion",
      "Sirène intelligente",
      "Monitoring 24h/7j",
    ],
    price: "À partir de 990€",
  },
  {
    icon: Zap,
    title: "Pack Énergie",
    description: "Optimisez votre consommation énergétique",
    features: [
      "Gestion du chauffage",
      "Contrôle de l'éclairage",
      "Suivi consommation",
      "Prises intelligentes",
      "Rapport mensuel d'économies",
    ],
    price: "À partir de 1490€",
  },
];

const features = [
  {
    icon: Wifi,
    title: "Contrôle à Distance",
    description: "Pilotez votre maison depuis n'importe où",
  },
  {
    icon: SmartphoneCharging,
    title: "Application Mobile",
    description: "Interface intuitive pour tous vos appareils",
  },
  {
    icon: Lock,
    title: "Sécurité Renforcée",
    description: "Protocoles de sécurité dernière génération",
  },
  {
    icon: Thermometer,
    title: "Confort Optimal",
    description: "Automatisation intelligente de la température",
  },
];

export default function Domotique() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <ScrollingEffect />
      <header className="container mx-auto px-6 py-8">
        <nav className="flex justify-between items-center">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <img src="/favicon.ico" alt="" className="w-8 h-8" />
              <span className="text-2xl font-bold">CyberSab</span>
            </div>
          </Link>
          <div className="space-x-8">
            <Link
              to="/"
              className="text-gray-300 hover:text-cyan-400 transition-colors"
            >
              Retour à l'accueil
            </Link>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="py-12 px-6">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-8">
            Maison{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-700">
              Intelligente
            </span>
          </h1>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Transformez votre maison en un espace connecté, sécurisé et économe
            en énergie
          </p>
        </div>
      </section>

      {/* Services Grid */}
      <section className="pb-20 px-6">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16">
            Pourquoi Choisir Nos Solutions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="text-center p-6">
                <div className="w-16 h-16 bg-cyan-400/20 rounded-full flex items-center justify-center mx-auto mb-6">
                  <feature.icon className="w-8 h-8 text-cyan-400" />
                </div>
                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">
            Prêt à Moderniser Votre Habitat ?
          </h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Contactez-nous pour une étude personnalisée de vos besoins en
            domotique
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-4 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors"
          >
            Demander un devis
            <ChevronRight className="ml-2 w-5 h-5" />
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
}

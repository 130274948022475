import React from "react";
import { Instagram, Linkedin, Mail } from "lucide-react";

function Footer() {
  return (
    <div>
      {/* Contact Section */}
      <section id="contact" className="py-20 bg-gray-800/50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">
            Contactez-nous
          </h2>
          <div className="max-w-2xl mx-auto">
            <div className="flex flex-col space-y-6">
              <a
                href="mailto:cybersab@outlook.fr"
                className="flex items-center space-x-4 bg-gray-700/30 p-4 rounded-lg hover:bg-gray-700/50 transition-colors"
              >
                <Mail className="w-6 h-6 text-cyan-400" />
                <span>cybersab@outlook.fr</span>
              </a>
              <a
                href="https://www.instagram.com/cyber.sab"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-4 bg-gray-700/30 p-4 rounded-lg hover:bg-gray-700/50 transition-colors"
              >
                <Instagram className="w-6 h-6 text-cyan-400" />
                <span>Instagram</span>
              </a>
              <a
                href="https://www.linkedin.com/company/cybersab"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-4 bg-gray-700/30 p-4 rounded-lg hover:bg-gray-700/50 transition-colors"
              >
                <Linkedin className="w-6 h-6 text-cyan-400" />
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="py-8 text-center text-gray-400">
        <div className="container mx-auto px-6">
          <p>
            © {new Date().getFullYear()}{" "}
            <a className="hover:text-cyan-400" href="/">
              CyberSab
            </a>
            . Tous droits réservés.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

import React from "react";
import ScrollingEffect from "../components/ScrollingEffect/ScrollingEffect";
import { Link } from "react-router-dom";
import { ProjectForm } from "../components/ProjectForm";

function Form() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <ScrollingEffect />
      <header className="container mx-auto px-6 py-8">
        <nav className="flex justify-between items-center">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <img src="/favicon.ico" alt="" className="w-8 h-8" />
              <span className="text-2xl font-bold">CyberSab</span>
            </div>
          </Link>
          <Link
            to="/"
            className="text-gray-300 hover:text-cyan-400 transition-colors"
          >
            Retour à l'accueil
          </Link>
        </nav>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-6 py-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Parlons de votre <span className="text-cyan-400">projet</span>
            </h1>
            <p className="text-xl text-gray-300">
              Remplissez le formulaire ci-dessous pour nous faire part de vos
              besoins. Nous vous recontacterons rapidement pour en discuter.
            </p>
          </div>

          <ProjectForm />
        </div>
      </main>

      <footer className="py-8 text-center text-gray-400">
        <div className="container mx-auto px-6">
          <p>
            © {new Date().getFullYear()}{" "}
            <a className="hover:text-cyan-400" href="/">
              CyberSab
            </a>
            . Tous droits réservés.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Form;

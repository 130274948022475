import React, { useState } from "react";
import ScrollingEffect from "../components/ScrollingEffect/ScrollingEffect";
import { Link } from "react-router-dom";
import {
  Rocket,
  HardDrive,
  MonitorSmartphone,
  ChevronUp,
  ChevronDown,
  ChevronRight,
} from "lucide-react";
import Footer from "../components/Footer";

function Home() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <ScrollingEffect />
      <header className="container mx-auto mt-[-9px] px-6 py-8 pb-16">
        <nav className="flex justify-between items-center mb-16">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <img src="/favicon.ico" alt="" className="w-8 h-8" />
              <span className="text-2xl font-bold">CyberSab</span>
            </div>
          </Link>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center px-6 py-3 border border-cyan-500 hover:bg-cyan-600 hover:border-cyan-500 rounded-lg font-semibold transition-colors focus:outline-none"
            >
              Services
              <span className="ml-2">
                {isDropdownOpen ? (
                  <ChevronUp className="w-5 h-5" />
                ) : (
                  <ChevronDown className="w-5 h-5" />
                )}
              </span>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-56 bg-gray-900 border border-cyan-500 rounded-lg shadow-lg z-50">
                <ul className="py-2">
                  <li>
                    <Link
                      to="/services/web"
                      className="block px-4 py-2 text-white hover:bg-cyan-600 transition-colors"
                    >
                      Développement Web
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/domotique"
                      className="block px-4 py-2 text-white hover:bg-cyan-600 transition-colors"
                    >
                      Domotique
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>

        <div className="max-w-3xl">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            Transformez votre vision en
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-700">
              {" "}
              réalité digitale
            </span>
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Développement web sur mesure pour donner vie à vos projets avec des
            solutions modernes et performantes.
          </p>
          <Link
            to="/form"
            className="inline-flex items-center px-6 py-3 bg-cyan-500 hover:bg-cyan-600 rounded-lg font-semibold transition-colors"
          >
            Démarrer votre projet
            <ChevronRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </header>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-800/50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Nos Services</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <MonitorSmartphone className="w-12 h-12 text-cyan-400" />,
                title: "Sites Web Responsifs",
                description:
                  "Des sites web adaptatifs offrant une expérience utilisateur optimale sur tous les appareils.",
              },
              {
                icon: <Rocket className="w-12 h-12 text-cyan-400" />,
                title: "Applications Web",
                description:
                  "Des applications web performantes et évolutives pour répondre à vos besoins spécifiques.",
              },
              {
                icon: <HardDrive className="w-12 h-12 text-cyan-400" />,
                title: "Hébergement",
                description:
                  "Un service d'hébergement fiable et sécurisé pour assurer la disponibilité et la performance de votre projet.",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="bg-gray-700/50 p-8 rounded-xl hover:transform hover:-translate-y-2 transition-all"
              >
                <div className="mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section id="expertise" className="py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-16">Technologies</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              "React.js",
              "TypeScript",
              "Node.js",
              "Tailwind CSS",
              "Next.js",
              "MongoDB",
              "PostgreSQL",
              "AWS",
            ].map((tech, index) => (
              <div
                key={index}
                className="bg-gray-700/30 p-4 rounded-lg text-center hover:bg-gray-700/50 transition-colors"
              >
                <span className="font-semibold">{tech}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
